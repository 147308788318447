import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { v4 as uuidv4 } from "uuid"
import parse from "html-react-parser"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Banner from "../components/Banner"
import ButtonLink from "../components/ButtonLink"

function ReadMore({ children, item }) {
  const text = children
  const [isReadMore, setIsReadMore] = useState(true)
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }
  return (
    <>
      <p>
        {isReadMore ? `${text.replace(/^(.{235}[^\s]*).*/, `$1`)}...` : text}
      </p>
      <div className="text-center mb-6">
        <button
          className={`btn ${item.btnColor}`}
          type="button"
          onClick={toggleReadMore}
        >
          {isReadMore
            ? `${item.readMoreButton.more}`
            : `${item.readMoreButton.less}`}
        </button>
      </div>
    </>
  )
}

ReadMore.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object]).isRequired,
  item: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

const EquipePage = function ({ data: { equipeJson }, location }) {
  return (
    <Layout>
      <Seo
        title={equipeJson.title}
        description={equipeJson.description}
        pathname={location.pathname}
      />
      <Banner
        background={equipeJson.banner.background}
        content={equipeJson.banner.content}
      />
      <div className="container">
        <section className="max-w-5xl mx-auto">
          {parse(equipeJson.intro)}
        </section>
        <section className="mb-6">
          <ul className="grid sm:grid-cols-2 md:grid-cols-3 gap-5">
            {equipeJson.list.map(function (item, i) {
              const liClass = i === 0 ? `bg-black text-white` : `bg-primary`
              const btnColor = i === 0 ? `btn-primary` : `btn-secondary`
              return (
                <li key={uuidv4()} className={`p-5 mb-10 ${liClass}`}>
                  <h3 className="text-center mb-5">{item.title}</h3>
                  <GatsbyImage
                    className="mb-4"
                    image={item.photo.childImageSharp.gatsbyImageData}
                    alt={item.name}
                  />
                  <ReadMore
                    item={{
                      readMoreButton: equipeJson.readMoreButton,
                      btnColor,
                    }}
                  >
                    {item.description}
                  </ReadMore>
                  <div className="text-center">
                    <h4>{item.where.title}</h4>
                    <p>{item.where.content}</p>
                  </div>
                </li>
              )
            })}
          </ul>
        </section>
        <section className="max-w-5xl mx-auto">
          {parse(equipeJson.others)}
          <div className="text-center">
            <ButtonLink item={equipeJson.joinUsButton} />
          </div>
        </section>
      </div>
    </Layout>
  )
}

EquipePage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default EquipePage

export const query = graphql`
  query EquipePageQuery {
    equipeJson {
      title
      description
      banner {
        background {
          publicURL
        }
        content
      }
      intro
      readMoreButton {
        more
        less
      }
      list {
        name
        title
        photo {
          childImageSharp {
            gatsbyImageData
          }
        }
        description
        where {
          title
          content
        }
      }
      others
      joinUsButton {
        icon
        label
        link
      }
    }
  }
`
